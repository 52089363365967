<template>
  <div class="content">
    <section class="searchUpView">
      <div class="searchUpMain">
        <el-input
          class="searchInput"
          placeholder="請輸入關鍵詞"
          v-model="keyword"
          @change="searchSubmit"
          @keyup.enter.native="getData"
          clearable
        >
          <i
            slot="suffix"
            @click="getData"
            class="el-input__icon el-icon-search"
          ></i>
        </el-input>

        <el-tabs class="tabsView" v-model="tabsActive">
          <el-tab-pane
            :label="`全部(${all_list.length})`"
            name="all"
          ></el-tab-pane>
          <el-tab-pane
            :label="`新聞中心(${news_list.length})`"
            name="news"
          ></el-tab-pane>
          <el-tab-pane
            :label="`案例分享(${case_list.length})`"
            name="case"
          ></el-tab-pane>
          <el-tab-pane
            :label="`知識庫(${knowledge_list.length})`"
            name="knowledge"
          ></el-tab-pane>
        </el-tabs>
      </div>
    </section>
    <div class="main">
      <template v-if="tabsActive == 'all'">
        <template v-if="all_list.length != 0">
          <router-link
            :to="item | router_to"
            class="item"
            v-for="(item, index) of all_list"
            :key="index"
          >
            <div class="title">{{ item.title }}</div>
            <p>{{ item.description }}</p>
          </router-link>
        </template>
        <empty-data v-else />
      </template>
      <template v-if="tabsActive == 'news'">
        <template v-if="news_list.length != 0">
          <router-link
            :to="`/news_details?id=${item.id}`"
            class="item"
            v-for="(item, index) of news_list"
            :key="index"
            ><div class="title">{{ item.title }}</div>
            <p>{{ item.description }}</p></router-link
          >
        </template>
        <empty-data v-else />
      </template>
      <template v-if="tabsActive == 'case'">
        <template v-if="case_list.length != 0">
          <router-link
            :to="`/operation_base_details?id=${item.id}`"
            class="item"
            v-for="(item, index) of case_list"
            :key="index"
            ><div class="title">{{ item.title }}</div>
            <p>{{ item.description }}</p></router-link
          >
        </template>
        <empty-data v-else />
      </template>
      <template v-if="tabsActive == 'knowledge'">
        <template v-if="knowledge_list.length != 0">
          <router-link
            :to="`/service_and_support?id=${item.id}`"
            class="item"
            v-for="(item, index) of knowledge_list"
            :key="index"
            ><div class="title">{{ item.title }}</div>
            <p>{{ item.description }}</p></router-link
          >
        </template>
        <empty-data v-else />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Search",
  data() {
    return {
      keyword: this.$route.query.keyword || "",
      tabsActive: "all",
      all_list: [],
      news_list: [],
      case_list: [],
      knowledge_list: [],
    };
  },
  filters: {
    router_to(data) {
      if (data.type == 1) {
        return `/operation_base_details?id=${data.id}`;
      }
      if (data.type == 2) {
        return `/news_details?id=${data.id}`;
      }
      if (data.type == 3) {
        return `/service_and_support?id=${data.id}`;
      }
      return "";
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    /**
     * 搜索
     */
    searchSubmit(e) {
      this.keyword = e;
    },

    /**
     * 处理数据
     * state : 1=案例分享 2=新闻中心 3=知识库
     */
    handleData(arr, state) {
      return arr.filter((item) => item.type == state);
    },
    /**
     * 获取数据
     */
    getData() {
      this.$http.search({ search: this.keyword }).then((res) => {
        if (res.code == 200) {
          this.all_list = res.data;
          this.case_list = this.handleData(res.data, 1);
          this.news_list = this.handleData(res.data, 2);
          this.knowledge_list = this.handleData(res.data, 3);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.el-icon-search {
  cursor: pointer;
  &:hover {
    color: #0075c1;
  }
}
.content {
  padding: 0 30px;
  @include respond-to(lg) {
    padding: 0;
  }
  .searchUpView {
    height: 160px;
    background-color: #f0f4f5;
    .searchUpMain {
      width: 872px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @include respond-to(lg) {
        width: 95%;
      }
      .searchInput {
        width: 670px;
        /deep/ .el-input__inner {
          border-radius: 20px;
          background-color: #fff;
        }
        @include respond-to(sm) {
          width: 100%;
        }
      }
      /deep/ .tabsView {
        margin-top: 38px;
        .el-tabs__active-bar {
          height: 3px;
          background-color: #0075c1;
        }
        .el-tabs__item {
          font-size: 16px;
          color: #333;
          height: 50px;
        }
        .el-tabs__header {
          margin-bottom: 3px;
        }
        .el-tabs__nav-wrap::after {
          background-color: transparent;
        }
      }
    }
  }
  .main {
    width: 872px;
    margin: 0 auto;
    min-height: 50vh;
    @include respond-to(lg) {
      width: 95%;
    }
    .item {
      padding: 40px 0;
      display: block;
      border-bottom: 1px solid #e6e6e6;
      .title {
        font-size: 24px;
        color: #333;
        margin-bottom: 20px;
      }
      p {
        font-size: 14px;
        color: #666;
        line-height: 1.8;
      }
      &:hover {
        .title {
          color: #0075c1;
        }
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
</style>